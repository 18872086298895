<template>
  <el-container style="height: 100%;">
    <el-aside width="200px" style="background-color: #252b4e">
      <el-menu :default-openeds="['1', '3']">
        <div class="text_center h_60 lh_60 color_fff" style="background-color: #252b4e">牧户管理后台</div>
        <el-submenu index="1">
          <template slot="title"><i class="el-icon-user">牧户信息</i></template>
          <el-menu-item-group>
            <!-- <template slot="title">牧户管理</template> -->
            <el-menu-item index="1-1">巴林右旗</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header class="w_full relative" style="text-align: left; font-size: 12px">
        <el-button type="primary" size="small">添加</el-button>
        <el-button type="success" size="small">导入</el-button>
        <el-button type="success" size="small">导出</el-button>
        <el-button type="danger" size="small">删除</el-button>

        <span class="ml_30 absolute right_20">admin</span>
      </el-header>

      <el-main>
        <el-table :data="tableData">
          <el-table-column prop="name" label="牧户" />
          <el-table-column prop="addr" label="地址" />
          <el-table-column prop="phone" label="电话" />
          <el-table-column prop="farm_name" label="牧场" />
          <el-table-column prop="num" label="牧场编号" />
        </el-table>
      </el-main>
    </el-container>
  </el-container>
</template>
  
<script>
export default {
  data() {
    const item = {
      date: '2021-6-02',
      name: 'admin',
    };
    return {
      tableData: [
        {
          name: '巴垃吉',
          phone: '176****8232',
          addr: '巴林右旗大坂镇布吐嘎查',
          farm_name: '巴垃吉牧场',
          num: '2174282'
        },
        {
          name: '萨如拉',
          phone: '137****4457',
          addr: '巴林右旗大坂镇古力古台嘎查',
          farm_name: '萨如拉牧场',
          num: '4263424'
        },
        {
          name: '那顺乌力吉',
          phone: '137****4457',
          addr: '巴林右旗大坂镇昭胡都格嘎查',
          farm_name: '那顺乌力吉牧场',
          num: '4263424'
        },
        {
          name: '孟和巴根',
          phone: '176****2345',
          addr: '巴林右旗大坂镇苏艾力嘎查',
          farm_name: '孟和巴根牧场',
          num: '4263424'
        },
        {
          name: '巴图吉日嘎啦',
          phone: '187****3345',
          addr: '巴林右旗大坂镇红旗嘎查',
          farm_name: '巴图吉日嘎啦牧场',
          num: '4874482'
        },
        {
          name: '额尔顿宝力高',
          phone: '187****3345',
          addr: '巴林右旗大坂镇十家子嘎查',
          farm_name: '额尔顿宝力高牧场',
          num: '1839482'
        },
        {
          name: '巴雅尔图',
          phone: '13743663290',
          addr: '巴林右旗大坂镇西哈嘎查',
          farm_name: '巴雅尔图牧场',
          num: '2374238'
        },
        {
          name: '孟和',
          phone: '189****3360',
          addr: '巴林右旗大坂镇大冷村',
          farm_name: '孟和牧场',
          num: '2328723'
        },
        {
          name: '白嘎力',
          phone: '186****7291',
          addr: '巴林右旗大坂镇嘎查',
          farm_name: '白嘎力牧场',
          num: '213131'
        },
        {
          name: '威力斯',
          phone: '158****3256',
          addr: '巴林右旗大坂镇温根吐嘎查',
          farm_name: '威力斯牧场',
          num: '232424'
        },
        {
          name: '巴根那',
          phone: '157****3234',
          addr: '巴林右旗大坂镇十家子嘎查',
          farm_name: '巴根那牧场',
          num: '7543432'
        },
      ]
    }
  }
};
</script>
  
<style>
.el-header {
  background-color: #0a2765 !important;
  color: orange;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
  